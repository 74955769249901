import React, { FC, useContext, useEffect, useState } from 'react';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { DesktopLogo, ProfileIcon } from '../../asset';
import SignInPageContainer from '../../pages/signin/signinPageContainer';
import SignupPageContainer from '../../pages/signup/signupPageContainer';
import { Paths } from '../../utils/constants';
import { isEmpty } from '../common/utils';
import { HeaderCart } from './HeaderCart/HeaderCart';
import './UpdatedHeaderDesktop.scss';
import MenuDropdown from './MenuDropdown';
import SubscriptionQuestions from '../subscriptionQuestions/subscriptionQuestions';
import { userContext } from '../../context/userContext';

interface UpdatedHeaderDesktopViewProps {
    userData: any;
    displaySignUp: boolean;
    displaySignIn: boolean;
    openSubscriptionModal: boolean;
    setOpenSubscriptionModal: (value: boolean) => void;
    handleSelect: (
        plan: number,
        subId: number,
        subName: string,
        subDesc: string
    ) => void;
    menuItems: Array<any>;
    showAccountMenu: boolean;
    setShowAccountMenu: (value: boolean) => void;
    setShowMainMenu: (value: boolean) => void;
    handleDisplaySignIn: () => void;
}

const desktopAccountMenu = [
    {
        menuLabel: 'Account',
        menuHref: Paths.ACCOUNT,
    },
];

const UpdatedHeaderDesktopView: FC<UpdatedHeaderDesktopViewProps> = ({
    userData,
    displaySignUp,
    displaySignIn,
    openSubscriptionModal,
    setOpenSubscriptionModal,
    handleSelect,
    menuItems,
    showAccountMenu,
    setShowAccountMenu,
    setShowMainMenu,
    handleDisplaySignIn,
}) => {
    const [showMenuOne, setShowMenuOne] = useState<boolean>(false);
    const [showMenuTwo, setShowMenuTwo] = useState<boolean>(false);
    const [voicesClicked, setVoicesClicked] = useState<boolean>(false);
    const [menuItemNumbers, setMenuItemNumbers] = useState<any>([]);
    const { plans, setPlans } = useContext(userContext);
    const navigate = useNavigate();

    const setMenusFalse = () => {
        setShowMenuOne(false);
        setShowMenuTwo(false);
    };

    const setAccountVoiceFalse = () => {
        setVoicesClicked(false);
        setShowAccountMenu(false);
    };

    const showDropdownOne = () => {
        setAccountVoiceFalse();
        setShowMenuOne(true);
        setShowMenuTwo(false);
    };

    const showDropdownTwo = () => {
        setAccountVoiceFalse();
        setShowMenuOne(false);
        setShowMenuTwo(true);
    };

    const handleAccountMenu = () => {
        setShowAccountMenu(true);
        setMenusFalse();
    };

    useEffect(() => {
        setShowMainMenu(false);
    }, []);

    return (
        <>
            <Navbar
                className="navbar-main"
                fixed="top"
                onMouseLeave={() => {
                    setMenusFalse();
                    setShowAccountMenu(false);
                }}
            >
                <Container fluid className="navbar-container m-0 p-0 w-100 justify-content-center">
                    {/* <Nav className="navbar-nav-desktop ps-2 justify-content-between">
                        <NavDropdown
                            title="Wines"
                            id="navbarScrollingDropdownOne"
                            className={`nav-dropdown-button ${
                                showMenuOne ? 'bottom-border-line' : ''
                            }`}
                            onMouseEnter={() => {
                                showDropdownOne();
                                setMenuItemNumbers([0, 1, 2, 3, 4]);
                            }}
                            children={''}
                        ></NavDropdown>
                        <Nav.Link
                            href={menuItems?.[5]?.menuHref}
                            className={`nav-link ${
                                voicesClicked ? 'bottom-border-line' : ''
                            }`}
                            onClick={() => {
                                navigate(menuItems?.[5]?.menuHref);
                            }}
                            onMouseEnter={() => {
                                setVoicesClicked(true);
                                setMenusFalse();
                            }}
                            onMouseLeave={() => setVoicesClicked(false)}
                        >
                            {menuItems?.[5]?.menuLabel}
                        </Nav.Link>
                        <NavDropdown
                            title="About Us"
                            id="navbarScrollingDropdownTwo"
                            className={`nav-dropdown-button ${
                                showMenuTwo ? 'bottom-border-line' : ''
                            }`}
                            onMouseEnter={() => {
                                showDropdownTwo();
                                setMenuItemNumbers([6, 7, 8, 9]);
                            }}
                            children={''}
                        ></NavDropdown>
                    </Nav> */}
                    <Navbar.Brand className="m-0" href={Paths.HOME}>
                        <DesktopLogo />
                    </Navbar.Brand>
                    {/* <Nav className={`cart-profile justify-content-end`}>
                        <div
                            className={`cart-profile-inner-div d-flex flex-row mx-2 justify-content-between`}
                            // ${
                            //     userData?.id !== -1 ||
                            //         ? 'justify-content-between'
                            //         : 'justify-content-end'
                            // }`}
                        >
                            {!isEmpty(userData?.first_name) && (
                                <Link
                                    to={Paths.CART}
                                    onClick={() => {
                                        setShowAccountMenu(false);
                                        setMenusFalse();
                                    }}
                                >
                                    <HeaderCart />
                                </Link>
                            )}
                            {isEmpty(userData?.first_name) && (
                                <Link
                                    to={`${Paths.BUNDLES}${Paths.BUNDLES_BILLING_INFO}`}
                                    onClick={() => {
                                        setShowAccountMenu(false);
                                        setMenusFalse();
                                    }}
                                >
                                    <HeaderCart />
                                </Link>
                            )}
                            <ProfileIcon
                                className="profile-icon"
                                onClick={() => {
                                    if (userData?.id === -1) {
                                        setMenusFalse();
                                        handleDisplaySignIn();
                                    }
                                }}
                                onMouseEnter={() =>
                                    userData?.id !== -1 && handleAccountMenu()
                                }
                            />
                        </div>
                    </Nav> */}
                </Container>

                {/* {(showMenuOne || showMenuTwo) && (
                    <MenuDropdown
                        menuItemNumbers={menuItemNumbers}
                        menuItems={menuItems}
                    />
                )}
                {showAccountMenu && (
                    <MenuDropdown
                        menuItemNumbers={[0]}
                        menuItems={desktopAccountMenu}
                        showSignOut={true}
                        setShowAccountMenu={setShowAccountMenu}
                    />
                )}
                {openSubscriptionModal ? (
                    <SubscriptionQuestions
                        setOpenSubscriptionModal={setOpenSubscriptionModal}
                        handleSelect={handleSelect}
                        plans={plans}
                        setPlans={setPlans}
                    />
                ) : (
                    <></>
                )}
                {displaySignUp ? <SignupPageContainer /> : <></>}
                {displaySignIn ? <SignInPageContainer /> : <></>} */}
            </Navbar>
        </>
    );
};

export default UpdatedHeaderDesktopView;
