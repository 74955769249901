import {
    createContext,
    Dispatch,
    FC,
    ReactNode,
    SetStateAction,
    useEffect,
    useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { getLocalStorage, setLocalStorage } from '../components/common/utils';
import { GlobalLoader } from '../components/Loaders/GLobalLoader';
import Toaster from '../components/toaster/toaster';
import {
    BREAD_CRUMBS_KEY,
    CarouselScreen,
    DeviceType,
    PageKeys,
    Paths,
    ToastType,
} from '../utils/constants';
import { guestTokenType, GuestTokenType } from '../types/globalSharedType';
import { getGuestToken } from '../services/paymentService';

interface GlobalProviderProps {
    children: ReactNode;
}

interface GlobalContextType {
    isGlobalLoader: boolean;
    setIsGlobalLoader: Dispatch<SetStateAction<boolean>>;
    deviceType: string;
    toaster: any;
    setToaster: Dispatch<SetStateAction<boolean>>;
    location: any;
    showNotification: (message?: string, delay?: number) => void;
    fetchGuestToken: (formData: guestTokenType) => void;
    guestToken: GuestTokenType;
    setGuestToken: Dispatch<SetStateAction<GuestTokenType>>;
}

export const GlobalContext = createContext<GlobalContextType>({
    isGlobalLoader: false,
    setIsGlobalLoader: () => {},
    deviceType: DeviceType?.MOBILE,
    toaster: {},
    setToaster: () => {},
    location: {},
    showNotification: () => {},
    fetchGuestToken: () => {},
    guestToken: {} as GuestTokenType,
    setGuestToken: () => {},
});

export const GlobalProvider: FC<GlobalProviderProps> = ({ children }) => {
    const [isGlobalLoader, setIsGlobalLoader] = useState<boolean>(false);
    const [deviceType, setDeviceType] = useState<string>(DeviceType.MOBILE);
    const [guestToken, setGuestToken] = useState<GuestTokenType>(
        {} as GuestTokenType
    );

    //const [location, setLocation] = useState<any>(window.location);
    const [toaster, setToaster] = useState<any>({
        showToaster: false,
        toasterMessage: '',
        delayToastSec: 0,
        toasterType: ToastType.PRIMARY,
    });
    const location = useLocation();

    const updateDimensions = () => {
        window.innerWidth > CarouselScreen.WIDTH_768 &&
            setDeviceType(DeviceType?.DESKTOP);
        window.innerWidth < CarouselScreen.WIDTH_768 &&
            window.innerWidth > CarouselScreen.WIDTH_575 &&
            setDeviceType(DeviceType?.TABLET);
        window.innerWidth < CarouselScreen.WIDTH_575 &&
            setDeviceType(DeviceType?.MOBILE);
    };

    useEffect(() => {
        if (!getLocalStorage(BREAD_CRUMBS_KEY)) {
            setLocalStorage(
                BREAD_CRUMBS_KEY,
                JSON.stringify([
                    {
                        text: 'Home',
                        href: Paths.HOME,
                        pageKey: PageKeys.HOME,
                    },
                ])
            );
        }

        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    const showNotification = (message?: string, delay?: number) => {
        setToaster({
            ...toaster,
            showToaster: true,
            toasterMessage:
                message || 'Oops something went wrong, please try again',
            // DO NOT CHANGE
            delayToastSec: 10000, // as per client request make it 20 seconds everywhere.,
        });
    };

    const fetchGuestToken = async (formData: guestTokenType) => {
        if (!guestToken?.access_token) {
            const body = {
                firstName: formData?.firstName,
                lastName: formData?.lastName,
                email: formData?.email,
                phone: formData?.phone,
                zip: formData?.zip,
            };
            try {
                const response: any = await getGuestToken(body);
                if (response) {
                    setGuestToken(response);
                }
                return response;
            } catch (error: any) {
                showNotification(error?.detail);
            }
        }
    };

    return (
        <GlobalContext.Provider
            value={{
                isGlobalLoader,
                setIsGlobalLoader,
                deviceType,
                toaster,
                setToaster,
                location,
                showNotification,
                guestToken,
                fetchGuestToken,
                setGuestToken,
            }}
        >
            {isGlobalLoader ? <GlobalLoader /> : <></>}
            {children}
            <Toaster setShow={setToaster} toaster={toaster} />
        </GlobalContext.Provider>
    );
};
