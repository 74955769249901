import { BundlePaymentIntentType } from '../types/bundlesPageTypes';
import { GiftPaymentIntentType } from '../types/giftCardTypes';
import { GUEST_USER, PaymentMethodsAPIs } from '../utils/constants';
import { deleteData, getData, postData } from './service';

export const getAllPaymentMethods = async () => {
    const { data, error } = await getData(PaymentMethodsAPIs.PAYMENT_METHOD);
    if (data) {
        return data;
    } else throw error;
};

export const setDefaultPaymentMethod = async (
    paymentMethodId: string,
    payload?: {}
) => {
    const { data, error } = await postData(
        `${PaymentMethodsAPIs.DEFAULT_PAYMENT_METHOD}?method_id=${paymentMethodId}`,
        payload
    );
    if (data) {
        return data;
    } else throw error;
};

export const attachPaymentMethod = async (
    paymentMethodId: string,
    payload: any
) => {
    const { data, error } = await postData(
        `${PaymentMethodsAPIs.PAYMENT_METHOD}?method_id=${paymentMethodId}`,
        payload
    );
    if (data) {
        return data;
    } else throw error;
};

export const detachPaymentMethod = async (paymentMethodId: string) => {
    const { data, error } = await deleteData(
        `${PaymentMethodsAPIs.PAYMENT_METHOD}?method_id=${paymentMethodId}`,
        {}
    );
    if (data) {
        return data;
    } else throw error;
};

export const receivePaymentIntent = async (payload: any) => {
    const { data, error } = await postData(
        `${PaymentMethodsAPIs.PAYMENT_INTENT}`,
        payload
    );
    if (data) {
        return data;
    } else throw error;
};

export const getPublicationKeyForStripe = async () => {
    const { data, error } = await getData(
        `${PaymentMethodsAPIs.PAYMENT_PUBLICATION_KEY}`
    );
    if (data) {
        return data;
    } else throw error;
};

export const getclientSecretForStripe = async () => {
    const { data, error } = await getData(
        `${PaymentMethodsAPIs.PAYMENT_INTENT_SETUP}`
    );
    if (data) {
        return data;
    } else throw error;
};

export const getGuestToken = async (body: any) => {
    const { data, error } = await postData(GUEST_USER.GUEST_USER_TOKEN, body);
    if (data) {
        return data;
    } else throw error;
};

export const getGuestPublicationKeyForStripe = async (guestToken: string) => {
    const { data, error } = await getData(
        `${PaymentMethodsAPIs.PAYMENT_PUBLICATION_KEY_GUEST}`,
        guestToken
    );
    if (data) {
        return data;
    } else throw error;
};

export const getGuestClientSecretForStripe = async (guestToken: string) => {
    const { data, error } = await getData(
        `${PaymentMethodsAPIs.PAYMENT_INTENT_SETUP_GUEST}`,
        guestToken
    );
    if (data) {
        return data;
    } else throw error;
};

export const postGuestPaymentIntent = async (
    payload: GiftPaymentIntentType | BundlePaymentIntentType,
    guestToken?: string
) => {
    const { data, error } = await postData(
        `${PaymentMethodsAPIs.PAYMENT_INTENT_GUEST}`,
        payload,
        guestToken
    );
    if (data) {
        return data;
    } else throw error;
};
