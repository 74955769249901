import { API_VERSION, LOGIN_API, UPDATE_USER_API } from '../../utils/constants';

export const loginAPI = {
    loginURL: `${LOGIN_API}/${API_VERSION}/login/access-token`,
    signUpURL: `login/sign_up`,
    signInURL: `login/sign_in`,
};

export const userAPI = {
    updateUserURL: `${UPDATE_USER_API}/${API_VERSION}/users/`,
};

export const customerAPI = {
    aboutCustomerURL: `customer/about_customer`,
};
