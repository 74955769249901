import React, { FC } from 'react';
import { DeviceType } from '../../utils/constants';
import { ReactComponent as LogoShort } from '../../asset/images/logoShort.svg';
import { Link, useNavigate } from 'react-router-dom';
import { Paths } from '../../utils/constants';
import './errorBoundary.scss';

interface MessagePageProps {
    deviceType?: string;
    displayLogo?: boolean;
    message?: string;
    description?: string;
}
export const MessagePage: FC<MessagePageProps> = ({
    deviceType,
    displayLogo = false,
    message = 'Oops',
    description = 'Something went wrong.',
}) => {
    const navigate = useNavigate();
    return (
        <>
            {displayLogo && (
                <div className="modalRoot">
                    <div className="modalLogo ">
                        <LogoShort />
                    </div>
                </div>
            )}
            <div className=" justify-content-center section-main position-absolute bottom-0  w-100">
                <h1
                    className={`text-center h1 ${
                        deviceType !== DeviceType.MOBILE
                            ? 'error-title-desktop'
                            : 'error-title'
                    }`}
                >
                    {message}
                </h1>
                <div className="w-75 m-auto px-4">
                    <p className="text-center">{description}</p>
                </div>

                <p
                    className={`text-center ${
                        deviceType !== DeviceType.MOBILE
                            ? 'error-top-margin-desktop'
                            : 'error-top-margin'
                    }`}
                >
                    How to get back on track:
                </p>
                <p className="text-center mt-3">
                    <label
                        className=" text-decoration-none "
                        onClick={(event) => {
                            event.preventDefault();
                            navigate(-1);
                        }}
                    >
                        <span className="nav-title cursor-pointer">
                            Go back{' '}
                        </span>
                    </label>
                    to the previous page.
                </p>
                <div className="w-75 m-auto px-5">
                    <p className="text-center mt-3">OR</p>
                </div>
                <p className="text-center d-flex flex-column mt-4">
                    <span className="m-1">Return to The Waves</span>
                    <Link
                        reloadDocument
                        to={Paths.HOME}
                        className="text-decoration-none me-1"
                    >
                        <span className="nav-title m-1">Home page.</span>
                    </Link>
                </p>
            </div>
        </>
    );
};
