import React, { FC } from 'react';
import { GlobalContext } from '../../context/globalContext';
import { MessagePage } from './MessagePage';
import './errorBoundary.scss';

export interface ErrorPageProps {
    displayLogo?: boolean;
}

const ErrorPage: FC<ErrorPageProps> = ({ displayLogo = false }) => {
    return (
        <GlobalContext.Consumer>
            {(props) => {
                return (
                    <MessagePage
                        deviceType={props.deviceType}
                        displayLogo={displayLogo}
                    />
                );
            }}
        </GlobalContext.Consumer>
    );
};

export default ErrorPage;
