import React, { FC, useContext } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { CartPlus, MobileLogo, ProfileIcon } from '../../asset';
import SignInPageContainer from '../../pages/signin/signinPageContainer';
import SignupPageContainer from '../../pages/signup/signupPageContainer';
import { Paths } from '../../utils/constants';
import AccountMenu from '../accountSideMenu/AccountMenu';
import { isEmpty } from '../common/utils';
import { HeaderCart } from './HeaderCart/HeaderCart';
import MainMenuMobile from '../../components/mainMenuMobile/MainMenuMobile';
import './UpdatedHeaderMobile.scss';
import SubscriptionQuestions from '../subscriptionQuestions/subscriptionQuestions';
import { userContext } from '../../context/userContext';

interface UpdatedHeaderMobileViewProps {
    userData: any;
    displaySignUp: boolean;
    displaySignIn: boolean;
    accountMenuItems: Array<any>;
    openSubscriptionModal: boolean;
    setOpenSubscriptionModal: (value: boolean) => void;
    handleSelect: (
        plan: number,
        subId: number,
        subName: string,
        subDesc: string
    ) => void;
    menuItems: Array<any>;
    showMainMenu: boolean;
    setShowMainMenu: (value: boolean) => void;
    showAccountMenu: boolean;
    setShowAccountMenu: (value: boolean) => void;
    handleProfileClick: () => void;
    handleDisplaySignIn: () => void;
}

const UpdatedHeaderMobileView: FC<UpdatedHeaderMobileViewProps> = ({
    userData,
    displaySignUp,
    displaySignIn,
    openSubscriptionModal,
    setOpenSubscriptionModal,
    handleSelect,
    menuItems,
    accountMenuItems,
    showMainMenu,
    setShowMainMenu,
    showAccountMenu,
    setShowAccountMenu,
    handleProfileClick,
    handleDisplaySignIn,
}) => {
    const { plans, setPlans } = useContext(userContext);

    return (
        <Navbar className="navbar-main" fixed="top">
            <Container fluid className="m-0 p-0 w-100 justify-content-center">
                {/* <Nav className="navbar-nav nav-block mx-2">
                    <CartPlus
                        className="ms-2"
                        onClick={() => setShowMainMenu(!showMainMenu)}
                    />
                </Nav> */}
                <Navbar.Brand className="m-0" href={Paths.HOME}>
                    <MobileLogo />
                </Navbar.Brand>
                {/* <Nav className="nav-block mx-2 justify-content-between">
                    {!isEmpty(userData?.first_name) && (
                        <Link to={Paths.CART}>
                            <HeaderCart />
                        </Link>
                    )}
                    {isEmpty(userData?.first_name) && (
                        <Link
                            to={`${Paths.BUNDLES}${Paths.BUNDLES_BILLING_INFO}`}
                        >
                            <HeaderCart />
                        </Link>
                    )}
                    <ProfileIcon
                        onClick={() => {
                            userData?.id === -1
                                ? handleDisplaySignIn()
                                : handleProfileClick();
                        }}
                    />
                </Nav> */}
            </Container>

            {/* {showMainMenu ? (
                <MainMenuMobile
                    menuItems={menuItems}
                    handleDisplaySignIn={handleDisplaySignIn}
                    handleProfileClick={handleProfileClick}
                    setShowMainMenu={setShowMainMenu}
                    showMainMenu={showMainMenu}
                />
            ) : (
                <></>
            )}
            {showAccountMenu ? (
                <AccountMenu
                    accountMenuItems={accountMenuItems}
                    handleProfileClick={handleProfileClick}
                    setShowAccountMenu={setShowAccountMenu}
                />
            ) : (
                <></>
            )}
            {openSubscriptionModal ? (
                <SubscriptionQuestions
                    setOpenSubscriptionModal={setOpenSubscriptionModal}
                    handleSelect={handleSelect}
                    plans={plans}
                    setPlans={setPlans}
                />
            ) : (
                <></>
            )}
            {displaySignUp ? <SignupPageContainer /> : <></>}
            {displaySignIn ? <SignInPageContainer /> : <></>} */}
        </Navbar>
    );
};

export default UpdatedHeaderMobileView;
