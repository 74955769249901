import { AddressBookAPIs } from '../utils/constants';
import { deleteData, getData, postData, putData } from './service';

export const getAddressBookPageItems = async () => {
    const { data, error } = await getData(AddressBookAPIs.ADDRESS);
    if (data) {
        return data;
    } else throw error;
};

export const deleteShippingAddress = async (body: any) => {
    const { data, error } = await deleteData(AddressBookAPIs.ADDRESS, body);
    if (data) {
        return data;
    } else throw error;
};

export const updateShippingAddress = async (body: any) => {
    const { data, error } = await putData(AddressBookAPIs.ADDRESS, body);
    if (data) {
        return data;
    } else throw error;
};

export const createShippingAddress = async (body: any) => {
    const { data, error } = await postData(AddressBookAPIs.ADDRESS, body);
    if (data) {
        return data;
    } else throw error;
};

export const setDefaultShippingAddress = async (body: any) => {
    const { data, error } = await putData(
        AddressBookAPIs.DEFAULT_SHIPPING_ADDRESS,
        body
    );
    if (data) {
        return data;
    } else throw error;
};

export const getBillingAddressItem = async () => {
    const { data, error } = await getData(AddressBookAPIs.BILLING_ADDRESS);
    if (data) {
        return data;
    } else throw error;
};

export const createBillingAddress = async (body: any) => {
    const { data, error } = await postData(
        AddressBookAPIs.BILLING_ADDRESS,
        body
    );
    if (data) {
        return data;
    } else throw error;
};

export const updateBillingAddress = async (body: any) => {
    const { data, error } = await putData(
        AddressBookAPIs.BILLING_ADDRESS,
        body
    );
    if (data) {
        return data;
    } else throw error;
};
