import React, { FC } from 'react';
import { useContext, useEffect, useState } from 'react';
import { userContext } from '../../context/userContext';
import { GlobalContext } from '../../context/globalContext';
import { getUserDataFromLocalToken } from '../../services/loginService';
import { DeviceType, Paths } from '../../utils/constants';
import UpdatedHeaderDesktopView from './UpdatedHeaderDesktopView';
import UpdatedHeaderMobileView from './UpdatedHeaderMobileView';
import { pushPanelEventsInDataLayer } from '../common/utils';
import { useLocation } from 'react-router-dom';

const HeaderContainer: FC = () => {
    const [showAccountMenu, setShowAccountMenu] = useState<boolean>(false);
    const [showMainMenu, setShowMainMenu] = useState<boolean>(false);

    const closeSubscriptionModalHandler = () => setOpenSubscriptionModal(false);
    const location = useLocation();

    const {
        userData,
        setUserData,
        setShowFooter,
        setChosenPlan,
        displaySignIn,
        setDisplaySignIn,
        displaySignUp,
        setDisplaySignUp,
        openSubscriptionModal,
        setOpenSubscriptionModal,
    } = useContext(userContext);
    const { deviceType } = useContext(GlobalContext);

    const menuItems = [
        {
            menuLabel: 'Curated Bundles',
            menuHref: Paths.BUNDLES,
        },
        {
            menuLabel: 'Browse All Wines',
            menuHref: Paths.BROWSE,
        },
        {
            menuLabel: 'Rare Bottle Room',
            menuHref: Paths.RARE_BOTTLE_ROOM,
        },
        {
            menuLabel: 'Purchase History',
            menuHref: Paths.PURCHASE_HISTORY,
        },
        {
            menuLabel: 'Gift Cards',
            menuHref: Paths.GIFT_CARD,
        },
        {
            menuLabel: 'Stories',
            menuHref: Paths.VOICE,
        },
        {
            menuLabel: 'How It Works',
            menuHref: Paths.HOW_IT_WORKS,
        },
        {
            menuLabel: 'Who We Are',
            menuHref: Paths.WHO_WE_ARE,
        },
        {
            menuLabel: 'FAQs',
            menuHref: Paths.FAQS,
        },
        {
            menuLabel: 'Press Page',
            menuHref: Paths.PRESS_PAGE,
        },
    ];

    const accountMenuItems = [
        {
            menuLabel: 'Account',
            menuHref: Paths.ACCOUNT,
        },
        // {
        //     menuLabel: "Wines You've Liked",
        //     menuHref: Paths.WINES_YOU_HAVE_LIKED,
        // },
        {
            menuLabel: 'Order Details',
            menuHref: Paths.ORDERS,
        },
        {
            menuLabel: 'Your Addresses',
            menuHref: Paths.ADDRESS_BOOK,
        },
        {
            menuLabel: 'Subscription',
            menuHref: Paths.MY_SUBSCRIPTION,
        },
        // {
        //     menuLabel: 'Social Profile',
        //     menuHref: '#',
        // },
    ];

    useEffect(() => {
        const userData: any = getUserDataFromLocalToken();
        if (userData) {
            setUserData(userData);
        }
    }, [setUserData]);

    const handleSelect = (
        plan: number,
        subId: number,
        subName: string,
        subDesc: string
    ) => {
        pushPanelEventsInDataLayer(
            'subscription_plan_modal',
            location?.pathname,
            userData?.id
        );
        setChosenPlan({
            id: subId,
            subscriptionName: subName,
            subscriptionDesc: subDesc,
        });
        setShowFooter(false);
        closeSubscriptionModalHandler();
        setDisplaySignUp(true);
    };

    const handleProfileClick = () => {
        setShowAccountMenu(!showAccountMenu);
    };

    const handleDisplaySignIn = () => {
        setOpenSubscriptionModal(false);
        setDisplaySignIn(true);
    };
    return deviceType === DeviceType.TABLET ||
        deviceType === DeviceType.MOBILE ? (
        <UpdatedHeaderMobileView
            userData={userData}
            displaySignUp={displaySignUp}
            displaySignIn={displaySignIn}
            openSubscriptionModal={openSubscriptionModal}
            setOpenSubscriptionModal={setOpenSubscriptionModal}
            handleSelect={handleSelect}
            accountMenuItems={accountMenuItems}
            menuItems={menuItems}
            showMainMenu={showMainMenu}
            setShowMainMenu={setShowMainMenu}
            showAccountMenu={showAccountMenu}
            setShowAccountMenu={setShowAccountMenu}
            handleProfileClick={handleProfileClick}
            handleDisplaySignIn={handleDisplaySignIn}
        />
    ) : (
        <UpdatedHeaderDesktopView
            userData={userData}
            displaySignUp={displaySignUp}
            displaySignIn={displaySignIn}
            openSubscriptionModal={openSubscriptionModal}
            setOpenSubscriptionModal={setOpenSubscriptionModal}
            handleSelect={handleSelect}
            menuItems={menuItems}
            showAccountMenu={showAccountMenu}
            setShowAccountMenu={setShowAccountMenu}
            setShowMainMenu={setShowMainMenu}
            handleDisplaySignIn={handleDisplaySignIn}
        />
    );
};

export default HeaderContainer;
