import axios from 'axios';
import { TW_TOKEN } from '../components/common/constants';
import { removeTWAccessToken } from '../components/common/utils';
import {
    API_RESPONSE_CODES,
    LOGIN_API,
    API_VERSION,
    ERRORS,
    Paths,
} from '../utils/constants';
import { HttpResponseCodes } from '../utils/enums';
const baseURL = `${LOGIN_API}/${API_VERSION}/`;

const config = {
    headers: {
        Authorization: '',
    },
};

const handleErrorResponse = async (err: any) => {
    const {
        UNAUTHORIZED,
        FORBIDDEN,
        NOT_FOUND,
        BAD_REQUEST,
        INTERNAL_SERVER_ERROR,
        PAYLOAD_TOO_LARGE,
        NOT_ACCEPTABLE,
    } = HttpResponseCodes;
    if (
        [
            UNAUTHORIZED,
            FORBIDDEN,
            NOT_FOUND,
            BAD_REQUEST,
            INTERNAL_SERVER_ERROR,
            PAYLOAD_TOO_LARGE,
            NOT_ACCEPTABLE,
        ].includes(err?.response?.status)
    ) {
        const { status, data } = err.response;
        if (status === UNAUTHORIZED) {
            // Logout the user
            removeTWAccessToken();
            window.location.href = Paths.HOME;
        }
        const error = { detail: data?.detail, errorCode: status };
        return { error, errorCode: status || BAD_REQUEST };
    }
    if (err) {
        const errRes = err.toJSON();
        if (errRes.message === ERRORS.NETWORK_ERROR) {
            // Logout the user
            removeTWAccessToken();
            window.location.href = Paths.HOME;
        }
        return {
            error: errRes,
            errorCode: errRes.status ? errRes.status : INTERNAL_SERVER_ERROR,
        };
    }
    return err;
};

const handleSuccessResponse = (res: any) => {
    const { SUCCESS } = API_RESPONSE_CODES;
    return res.status === SUCCESS ? res.data : res;
};

export const getData = (url: string, guestToken?: string) => {
    const accessToken = guestToken || localStorage.getItem(TW_TOKEN);
    if (!accessToken) {
        return axios
            .get(`${baseURL}${url}`)
            .then((res) => handleSuccessResponse(res))
            .catch((err) => handleErrorResponse(err));
    } else {
        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`;
            return axios
                .get(`${baseURL}${url}`, config)
                .then((res) => handleSuccessResponse(res))
                .catch((err) => handleErrorResponse(err));
        }
        return '';
    }
};

export const postData = (url: string, body: any, guestToken?: string) => {
    const accessToken = guestToken || localStorage.getItem(TW_TOKEN);
    if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return axios
        .post(`${baseURL}${url}`, body, config)
        .then((res) => handleSuccessResponse(res))
        .catch((err) => handleErrorResponse(err));
};

export const patchData = (url: string, body: any) => {
    const accessToken = localStorage.getItem(TW_TOKEN);
    if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return axios
        .patch(`${baseURL}${url}`, body, config)
        .then((res) => handleSuccessResponse(res))
        .catch((err) => handleErrorResponse(err));
};

export const putData = (url: string, body: any) => {
    const accessToken = localStorage.getItem(TW_TOKEN);
    if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return axios
        .put(`${baseURL}${url}`, body, config)
        .then((res) => handleSuccessResponse(res))
        .catch((err) => handleErrorResponse(err));
};

export const deleteData = (url: string, body: any) => {
    const accessToken = localStorage.getItem(TW_TOKEN);
    if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return axios
        .delete(`${baseURL}${url}`, {
            data: body,
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        })
        .then((res) => handleSuccessResponse(res))
        .catch((err) => handleErrorResponse(err));
};
