import React, { FC } from 'react';
import { Spinner } from 'react-bootstrap';
import './loader.scss';

export const GlobalLoader: FC = () => {
    return (
        <div className="globalloader-container">
            <Spinner color="white" animation="border" role="status" />
            <span className="loading-text">Loading...</span>
        </div>
    );
};
