import React, { useEffect } from 'react';
import { Row, Alert } from 'react-bootstrap';
import { isEmpty } from '../common/utils';
import './toasterStyle.scss';

interface Props {
    setShow: (arg0: any) => void;
    toaster: any;
}

const Toaster = ({ setShow, toaster }: Props) => {
    useEffect(() => {
        const toastDelayTimer = setTimeout(() => {
            if (
                !isEmpty(toaster?.delayToastSec) &&
                toaster?.showToaster &&
                parseInt(toaster?.delayToastSec) >= 1000
            ) {
                setShow({ ...toaster, showToaster: false });
            }
        }, parseInt(toaster?.delayToastSec));
        return () => clearTimeout(toastDelayTimer);
    }, [toaster?.delayToastSec, toaster?.showToaster]);

    return (
        <>
            {toaster?.showToaster && (
                <Row className="toaster-main d-flex">
                    <Alert
                        className="d-flex align-items-center justify-content-between flex-row-reverse"
                        onClose={() =>
                            setShow({ ...toaster, showToaster: false })
                        }
                        // variant={toaster.toasterType}
                        dismissible
                    >
                        <span className="check-out-button">
                            {toaster?.toasterMessage?.toUpperCase()}
                        </span>
                    </Alert>
                </Row>
            )}
        </>
    );
};

export default Toaster;
