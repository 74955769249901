import {
    createContext,
    Dispatch,
    FC,
    ReactNode,
    SetStateAction,
    useEffect,
    useState,
} from 'react';
import { getAccountPageItems } from '../services/accountService';
import { getAddressBookPageItems } from '../services/addressBookService';

interface userContextProps {
    children: ReactNode;
}

export interface userData {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    contact_no: string;
    date_of_birth: string;
    oauth_user_id: string;
    exp: number;
}

interface chosenPlan {
    id: number;
    subscriptionName: string;
    subscriptionDesc: string;
}
export interface accountDetails {
    firstName: string;
    lastName: string;
    email: string;
    contactNo: string;
    dateOfBirth: string;
    gender: string;
    order: {
        orderId: number;
        status: string;
        date: string;
        value: string;
    };
    address: {
        shippingAddress: any;
        billingAddress: any;
    };
    subscription: any;
    paymentDetail: any;
    socialProfile: any;
}

interface UserContextType {
    userData: userData;
    setUserData: Dispatch<SetStateAction<userData>>;
    showFooter: boolean;
    setShowFooter: Dispatch<SetStateAction<boolean>>;
    plans: any;
    setPlans: Dispatch<SetStateAction<any>>;
    displaySignUp: boolean;
    setDisplaySignUp: Dispatch<SetStateAction<boolean>>;
    displaySignIn: boolean;
    setDisplaySignIn: Dispatch<SetStateAction<boolean>>;
    userAccountDetails: accountDetails;
    userAddresses: any;
    setUserAddresses: Dispatch<SetStateAction<any>>;
    setUserAccountDetails: Dispatch<SetStateAction<accountDetails>>;
    callAccountDetails: boolean;
    setCallAccountDetails: Dispatch<SetStateAction<boolean>>;
    chosenPlan: chosenPlan;
    setChosenPlan: Dispatch<SetStateAction<chosenPlan>>;
    openSubscriptionModal: boolean;
    setOpenSubscriptionModal: Dispatch<SetStateAction<boolean>>;
}

export const defaultUserData = {
    id: -1,
    first_name: '',
    last_name: '',
    email: '',
    contact_no: '',
    date_of_birth: '',
    oauth_user_id: '',
    exp: -1,
};

const defaultChosenPlan = {
    id: 0,
    subscriptionName: '',
    subscriptionDesc: '',
};

const defaultAccountDetails = {
    firstName: '',
    lastName: '',
    email: '',
    contactNo: '',
    dateOfBirth: '',
    gender: '',
    order: {
        orderId: 0,
        status: '',
        date: '',
        value: '',
    },
    address: {
        shippingAddress: {},
        billingAddress: {},
    },
    subscription: {},
    paymentDetail: {},
    socialProfile: {},
};

export const userContext = createContext<UserContextType>({
    userData: defaultUserData,
    setUserData: () => {},
    showFooter: true,
    setShowFooter: () => {},
    plans: [],
    setPlans: () => {},
    displaySignUp: false,
    setDisplaySignUp: () => {},
    displaySignIn: false,
    setDisplaySignIn: () => {},
    userAccountDetails: defaultAccountDetails,
    userAddresses: {},
    setUserAddresses: () => {},
    setUserAccountDetails: () => {},
    callAccountDetails: true,
    setCallAccountDetails: () => {},
    chosenPlan: defaultChosenPlan,
    setChosenPlan: () => {},
    openSubscriptionModal: false,
    setOpenSubscriptionModal: () => {},
});

export const UserContextProvider: FC<userContextProps> = ({ children }) => {
    const [userData, setUserData] = useState<userData>(defaultUserData);
    const [showFooter, setShowFooter] = useState(true);
    const [plans, setPlans] = useState<any>();
    const [displaySignIn, setDisplaySignIn] = useState<boolean>(false);
    const [displaySignUp, setDisplaySignUp] = useState<boolean>(false);
    const [userAccountDetails, setUserAccountDetails] = useState<any>({});
    const [userAddresses, setUserAddresses] = useState<any>({});
    const [callAccountDetails, setCallAccountDetails] = useState<boolean>(true);
    const [chosenPlan, setChosenPlan] = useState<chosenPlan>(defaultChosenPlan);
    const [openSubscriptionModal, setOpenSubscriptionModal] =
        useState<boolean>(false);

    useEffect(() => {
        const getUserAccountDetails = async () => {
            try {
                const data: any = await getAccountPageItems();
                setUserAccountDetails(data);
            } catch (error) {
                setUserAccountDetails({});
            }
        };
        const getDefaultAddress = async () => {
            try {
                const data: any = await getAddressBookPageItems();
                setUserAddresses(data);
            } catch (error) {
                setUserAccountDetails({});
            }
        };
        if (userData?.first_name && callAccountDetails) {
            getUserAccountDetails();
            getDefaultAddress();
            setCallAccountDetails(false);
        }
    }, [userData?.first_name, callAccountDetails]);

    return (
        <userContext.Provider
            value={{
                userData,
                setUserData,
                showFooter,
                setShowFooter,
                plans,
                setPlans,
                displaySignUp,
                setDisplaySignUp,
                displaySignIn,
                setDisplaySignIn,
                userAccountDetails,
                userAddresses,
                setUserAddresses,
                setUserAccountDetails,
                callAccountDetails,
                setCallAccountDetails,
                chosenPlan,
                setChosenPlan,
                openSubscriptionModal,
                setOpenSubscriptionModal,
            }}
        >
            {children}
        </userContext.Provider>
    );
};
