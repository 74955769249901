export enum aspectRatioKeys {
    DEFAULT = 'DEFAULT',
    STORIES = 'STORIES',
    CAROUSEL = 'CAROUSEL',
    HEROBANNERHORIZONTAL = 'HEROBANNERHORIZONTAL',
    HEROBANNERVERTICAL = 'HEROBANNERVERTICAL',
    SIDELINEBLOCK = 'SIDELINEBLOCK',
    LIFESTYLECATEGORIES = 'LIFESTYLECATEGORIES',
    HPWHYWEDIFF = 'HPWHYWEDIFF',
    MAINWRAPPER = 'MAINWRAPPER',
}

export enum wineTypes {
    RED = 'red',
    ROSE = 'rose',
    WHITE = 'white',
    ORANGE = 'orange',
    SPARKLING = 'sparkling',
    ROSÉ = 'rosé',
}

export enum videoAspectRatioPercentage {
    MOBILE = 177.77,
    DESKTOP = 56.25,
}

export enum HeroBannerTypes {
    HEROV1 = 'heroV1',
    HEROV2 = 'heroV2',
    HEROV3 = 'heroV3',
    HEROV4 = 'heroV4',
    HEROV5 = 'heroV5',
    HEROV6 = 'heroV6',
}

export enum HttpResponseCodes {
    BAD_REQUEST = 400,
    UNAUTHORIZED = 401,
    PAYMENT_REQUIRED = 402,
    FORBIDDEN = 403,
    NOT_FOUND = 404,
    INTERNAL_SERVER_ERROR = 500,
    PAYLOAD_TOO_LARGE = 413,
    NOT_ACCEPTABLE = 406,
}

export enum StripeResponse {
    SUCCESS = 'succeeded',
    FAILURE = 'failure',
}

export enum BreadCumPagesText {
    ACCOUNT = 'Account',
    WINES_YOU_HAVE_LIKED = 'Your Wine Feedback',
    ORDERS = 'Order Details',
    ADDRESS_BOOK = 'Your Addresses',
    MY_SUBSCRIPTION = 'Subscription',
}

export enum EventGroupsForGA {
    PANEL_VISIBLE = 'panel_visible',
    KEY_BUTTON_CLICK = 'key_button_click',
}

export enum OrderType {
    GIFT_CARD = 'gift_card',
    BUNDLES = 'bundle',
    EXPERT_PICK = 'expert_pick',
    NORMAL = 'normal',
}

export enum DefaultMessage {
    MESSAGE = 'It’s time to rethink what you drink! I’m excited to share the world’s best organic, chemical-free wines with you. I hope you enjoy The Waves!',
}
