import {
    FACEBOOK_CLIENT_ID,
    FACEBOOK_SCOPE,
    GOOGLE_CLIENT_ID,
    GOOGLE_SCOPE,
    INSTAGRAM_CLIENT_ID,
    INSTAGRAM_SCOPE,
} from '../../utils/constants';

export const loginCred = {
    URI: window.location.origin,
    google: {
        CLIENT_ID: GOOGLE_CLIENT_ID,
        SCOPE: GOOGLE_SCOPE,
        UX_MODE: 'popup',
    },
    facebook: {
        CLIENT_ID: FACEBOOK_CLIENT_ID,
        SCOPE: FACEBOOK_SCOPE,
        STATE: '{st=state123abc,ds=123456789}',
    },
    instagram: {
        CLIENT_ID: INSTAGRAM_CLIENT_ID,
        SCOPE: INSTAGRAM_SCOPE,
    },
};

export const authProviders = {
    GOOGLE: 'google',
    FACEBOOK: 'facebook',
    INSTAGRAM: 'instagram',
};

export const TW_TOKEN = 'tw_token';

//Value is in pixel
export const imageResponsiveRatio = {
    desktopHeight: '500',
    desktopWidth: '500',
    mobileHeight: '250',
    mobileWidth: '250',
};

export const Tags = {
    HEADER_TAG: 'header_tag',
    FILTER_TAG: 'filter_tag',
    RARE_FILTER_TAG: 'rare_filter_tag',
    EXPERT_PICK_TAG: 'expert_pick_tag',
    GIFT_CARD_LIST_TAG: 'gift_card_list_tag',
    GIFT_CARD_SESSION: 'gift_card_session',
    IS_SURVEY_FLAG: 'is_survey_flag',
    SURVEY_EMAIL: 'survey_email',
    SURVEY_PLAN: 'survey_plan',
    SURVEY_FLOW_ACTIVE: 'survey_flow_active',
    IS_ALREADY_CUSTOMER: 'is_already_customer',
    CUSTOM_BUNDLES_PAGE: 'custom_bundles_page_id',
    PURCHASE_HISTORY_JWT: 'purchase_history_jwt',
};

export const UserSplashInfo = {
    USER_AGE: 'user_age',
    YES: 'yes',
    NO: 'no',
};
