import { GCBagDataType } from '../../types/giftCardTypes';
import { MapsCoordinates } from '../../types/googleMapsTypes';
import {
    aspectRatio,
    BREAD_CRUMBS_KEY,
    GOOGLE_EMBED_MAP_URL,
    GOOGLE_MAPS_KEY,
    PageKeys,
    Paths,
    RegExpressions,
} from '../../utils/constants';
import { aspectRatioKeys, EventGroupsForGA } from '../../utils/enums';
import { BreadcrumObject } from '../breadcrumb/Breadcrumb.model';
import { TW_TOKEN, Tags, UserSplashInfo } from './constants';

export function getQueryVariable(location: Location, variable: string): string {
    const query = location.search.substring(1);
    const queryParams = query.split('&');
    const [code] = queryParams
        .map((queryParam) => {
            const params = queryParam.split('=');
            return params[0] === variable ? params[1] : '';
        })
        .filter((isCodeAvailable) => isCodeAvailable);
    return code;
}

export const popupWindow = (
    url: string,
    windowName: string,
    win: any,
    w: number,
    h: number
) => {
    const y = win.top.outerHeight / 2 + win.top.screenY - h / 2;
    const x = win.top.outerWidth / 2 + win.top.screenX - w / 2;
    return win.open(
        url,
        windowName,
        `scrollbars=no,toolbar=no,location=no,titlebar=no,directories=no,status=no,menubar=no, width=${w}, height=${h}, top=${y}, left=${x}`
    );
};

export const storeTWAccessToken = (token: string) => {
    localStorage.setItem(TW_TOKEN, token);
};

export const getTWAccessToken = (): string | null => {
    return localStorage.getItem(TW_TOKEN);
};

export const removeTWAccessToken = () => {
    localStorage.removeItem(TW_TOKEN);

    //clear session here
    sessionStorage.clear();
};

export const isEmpty = (value: any) => {
    return (
        value === undefined ||
        value === null ||
        (typeof value === 'object' && Object.keys(value).length === 0) ||
        (typeof value === 'string' && value.trim().length === 0)
    );
};

export const calculateAge = (dateOfBirth: string) => {
    let dob = new Date(dateOfBirth);
    let month_diff = Date.now() - dob.getTime();
    let age_dt = new Date(month_diff);
    let year = age_dt.getUTCFullYear();
    let age = Math.abs(year - 1970);
    return age;
};

export const updateURL = (
    imageURL: string,
    component:
        | aspectRatioKeys.HEROBANNERHORIZONTAL
        | aspectRatioKeys.HEROBANNERVERTICAL
        | aspectRatioKeys.CAROUSEL
        | aspectRatioKeys.DEFAULT
        | aspectRatioKeys.SIDELINEBLOCK
        | aspectRatioKeys.LIFESTYLECATEGORIES
        | aspectRatioKeys.HPWHYWEDIFF
        | aspectRatioKeys.MAINWRAPPER
        | aspectRatioKeys.STORIES = aspectRatioKeys.DEFAULT,
    isSmart = true,
    trim?: boolean
) => {
    const isStagingBranch = imageURL
        ? new URL(imageURL)?.searchParams?.get('branch') === 'staging'
        : '';
    return window.innerWidth > 575
        ? imageURL +
              `${isStagingBranch ? '&' : '?'}` +
              `crop=${aspectRatio[component].DESKTOP}${
                  isSmart ? ',smart' : ''
              }` +
              (trim ? `&trim=150,150,150,150` : '') +
              '&format=webply&quality=100'
        : imageURL +
              `${isStagingBranch ? '&' : '?'}` +
              `crop=${aspectRatio[component].MOBILE}${
                  isSmart ? ',smart' : ''
              }` +
              (trim ? `&trim=150,150,150,150` : '') +
              '&format=webply&quality=100';
};

export const capatilizeString = (stringText: string) =>
    `${stringText.charAt(0).toUpperCase()}${stringText.toLowerCase().slice(1)}`;

export const getFormattedAddress = (address: string): string[] => {
    const addArray: string[] = [];
    if (Array.isArray(address?.split(','))) {
        addArray.push(address.split(',')[0]);
        addArray.push(
            address?.split(',').slice(1, address?.split(',').length).join(', ')
        );
    }
    return addArray;
};

//--------------------------------------------

export const setHeaderTagInSession = (headerTag: string) => {
    sessionStorage.setItem(Tags.HEADER_TAG, headerTag);
};

export const getHeaderTagInSession = () => {
    const headerTag = sessionStorage.getItem(Tags.HEADER_TAG)
        ? sessionStorage.getItem(Tags.HEADER_TAG)
        : '';
    return headerTag;
};

export const setFilterTagInSession = (filterTag: string) => {
    sessionStorage.setItem(Tags.FILTER_TAG, filterTag);
};

export const getFilterTagInSession = () => {
    const filterTag = sessionStorage.getItem(Tags.FILTER_TAG)
        ? sessionStorage.getItem(Tags.FILTER_TAG)
        : '';
    return filterTag;
};

export const setRareFilterTagInSession = (filterTag: string) => {
    sessionStorage.setItem(Tags.RARE_FILTER_TAG, filterTag);
};

export const getRareFilterTagInSession = () => {
    const filterTag = sessionStorage.getItem(Tags.RARE_FILTER_TAG)
        ? sessionStorage.getItem(Tags.RARE_FILTER_TAG)
        : '';
    return filterTag;
};

export const setExpertPickFilterTagInSession = (filterTag: string) => {
    sessionStorage.setItem(Tags.EXPERT_PICK_TAG, filterTag);
};

export const getExpertPickFilterTagInSession = () => {
    const filterTag = sessionStorage.getItem(Tags.EXPERT_PICK_TAG)
        ? sessionStorage.getItem(Tags.EXPERT_PICK_TAG)
        : '';
    return filterTag;
};

export const setGiftCardListInSession = (filterTag: Array<GCBagDataType>) => {
    sessionStorage.setItem(Tags.GIFT_CARD_LIST_TAG, JSON.stringify(filterTag));
};

export const getGiftCardListInSession = () => {
    const filterTag = sessionStorage.getItem(Tags.GIFT_CARD_LIST_TAG)
        ? JSON.parse(sessionStorage.getItem(Tags.GIFT_CARD_LIST_TAG)!)
        : [];
    return filterTag;
};

export const setIsSurveyFlowActive = (filterTag: boolean) => {
    sessionStorage.setItem(Tags.SURVEY_FLOW_ACTIVE, JSON.stringify(filterTag));
};

export const getIsSurveyFlowActive = () => {
    const filterTag = sessionStorage.getItem(Tags.SURVEY_FLOW_ACTIVE)
        ? JSON.parse(sessionStorage.getItem(Tags.SURVEY_FLOW_ACTIVE)!)
        : false;
    return filterTag;
};

export const setSurveyFlagInSession = (filterTag: boolean) => {
    sessionStorage.setItem(Tags.IS_SURVEY_FLAG, JSON.stringify(filterTag));
};

export const getSurveyFlagInSession = () => {
    const filterTag = sessionStorage.getItem(Tags.IS_SURVEY_FLAG)
        ? JSON.parse(sessionStorage.getItem(Tags.IS_SURVEY_FLAG)!)
        : false;
    return filterTag;
};

export const setSurveyEmailInSession = (filterTag: string) => {
    sessionStorage.setItem(Tags.SURVEY_EMAIL, JSON.stringify(filterTag));
};

export const getSurveyEmailfromSession = () => {
    const filterTag: string = sessionStorage.getItem(Tags.SURVEY_EMAIL)
        ? JSON.parse(sessionStorage.getItem(Tags.SURVEY_EMAIL)!)
        : '';
    return filterTag;
};

export const setSurveyPlanInSession = (filterTag: string) => {
    sessionStorage.setItem(Tags.SURVEY_PLAN, JSON.stringify(filterTag));
};

export const getSurveyPlanfromSession = () => {
    const filterTag: string = sessionStorage.getItem(Tags.SURVEY_PLAN)
        ? JSON.parse(sessionStorage.getItem(Tags.SURVEY_PLAN)!)
        : '';
    return filterTag;
};

export const removeSurveyPlanInSession = () => {
    sessionStorage.removeItem(Tags?.SURVEY_PLAN);
};

export const setIsAlreadyACustomerInSession = (filterTag: boolean) => {
    sessionStorage.setItem(Tags.IS_ALREADY_CUSTOMER, JSON.stringify(filterTag));
};

export const getIsAlreadyACustomerInSession = () => {
    const filterTag = sessionStorage.getItem(Tags.IS_ALREADY_CUSTOMER)
        ? JSON.parse(sessionStorage.getItem(Tags.IS_ALREADY_CUSTOMER)!)
        : false;
    return filterTag;
};

export const getLocalStorage = (key: string) => {
    const isKeyValuePresent = localStorage.getItem(key);

    if (!isKeyValuePresent) return null;

    return isKeyValuePresent;
};

export const setLocalStorage = (key: string, data: any) => {
    try {
        localStorage.setItem(key, data);
        return true;
    } catch (error) {
        return false;
    }
};

export const updateBreadCrumsArray = (
    pageKey: string,
    previousBreadCrumArray: BreadcrumObject[],
    currentBreadCrum: BreadcrumObject
): BreadcrumObject[] => {
    //Slices are to set level or to home level ( LEVEL 0 )
    const sliceArray = (
        array: BreadcrumObject[],
        startIndex: number,
        len: number = 0
    ) => {
        const spliceInsertion = startIndex;
        const spliceLen =
            len !== 0 ? len : array.length - (spliceInsertion - 1);
        array.splice(spliceInsertion, spliceLen);
    };

    const { HOME } = Paths;

    const HomeBreadCrum: BreadcrumObject = {
        text: 'Home',
        href: HOME,
        pageKey: PageKeys.HOME,
    };

    if (previousBreadCrumArray.length === 0) {
        previousBreadCrumArray.push(HomeBreadCrum);
        previousBreadCrumArray.push(currentBreadCrum);

        return previousBreadCrumArray;
    }

    const index = previousBreadCrumArray
        .map((obj) => obj.pageKey)
        .indexOf(pageKey);

    if (index === -1 && previousBreadCrumArray.length > 1) {
        //check for Level 1 Crums
        if (
            pageKey === PageKeys.BY_SUBSCRIPTION ||
            pageKey === PageKeys.BY_BOTTLE ||
            pageKey === PageKeys.VOICES ||
            pageKey === PageKeys.WHO_WE_ARE ||
            pageKey === PageKeys.WHY_WE_ARE_DIFFERENT ||
            pageKey === PageKeys.ACCOUNT ||
            pageKey === PageKeys.ORDERS ||
            pageKey === PageKeys.ADDRESS_BOOK ||
            pageKey === PageKeys.MY_SUBSCRIPTION ||
            pageKey === PageKeys.BUNDLES
        ) {
            sliceArray(previousBreadCrumArray, 1, 0);
        }

        //Push Current Level
        previousBreadCrumArray.push(currentBreadCrum);
    } else {
        //Chech if level is dynamic product or stories level
        if (
            pageKey === PageKeys.PRODUCT_DETAILS ||
            pageKey === PageKeys.VOICE_DETAILS
        ) {
            //Check if Already their is other  similar data present then slice array at same index.
            if (index !== -1) sliceArray(previousBreadCrumArray, index, 0);

            //Push Current Level
            previousBreadCrumArray.push(currentBreadCrum);
        } else {
            sliceArray(previousBreadCrumArray, index + 1, 0);
        }
    }

    return previousBreadCrumArray;
};

export const getBreadCrumbsData = (
    pageKey: string,
    currentBreadCrum: BreadcrumObject
): BreadcrumObject[] => {
    const localBreadCrumbsArray = getLocalStorage(BREAD_CRUMBS_KEY);

    let initialBreadCrumb: BreadcrumObject[] = [];

    if (!localBreadCrumbsArray) {
        initialBreadCrumb = updateBreadCrumsArray(
            pageKey,
            initialBreadCrumb,
            currentBreadCrum
        );
    } else {
        initialBreadCrumb = updateBreadCrumsArray(
            pageKey,
            <BreadcrumObject[]>JSON.parse(localBreadCrumbsArray as string),
            currentBreadCrum
        );
    }

    setLocalStorage(BREAD_CRUMBS_KEY, JSON.stringify(initialBreadCrumb));

    return initialBreadCrumb;
};

export const convertHTMLToPlainText = (htmlText: string) => {
    // Create a new div element
    var tempDivElement = document.createElement('div');

    // Set the HTML content with the given value
    tempDivElement.innerHTML = htmlText;

    // Retrieve the text property of the element
    return tempDivElement.textContent || tempDivElement.innerText || '';
};

// Helper function to formate Path url with single id field
export const createSingleQueryPath = (basePath: string, id: string) =>
    basePath?.trim().length !== 0 && id?.trim().length !== 0
        ? `${basePath}?${id}`
        : '';

export const generateCardNumber = (
    cardNumber: string,
    replaceCharaters: string,
    length: number
) => {
    if (cardNumber?.trim().length === 0) return '';

    let result = ' ';
    const charactersLength = replaceCharaters.length;
    for (let i = 1; i <= length; i++) {
        result += replaceCharaters.charAt(
            Math.floor(Math.random() * charactersLength)
        );

        if (i % 4 === 0) result += ' ';
    }

    return result + cardNumber;
};

export const testRegEX = async (regEx: RegExp, str: string) => {
    const result = regEx.test(str);
    return result;
};

export const isContactNumberValid = async (number: string) => {
    const result = await testRegEX(RegExpressions.MOBNUMBER, number);
    return result;
};

export const isNameValid = async (name: string) => {
    const result = await testRegEX(RegExpressions.NAME, name);
    return result;
};

export const isZipCodeValid = async (name: string) => {
    const result =
        (await testRegEX(RegExpressions.ZIPCODE, name)) &&
        name.length > 0 &&
        name.length === 5;

    return result;
};

export const isAddressValid = async (name: string) => {
    const result = await testRegEX(RegExpressions.ADDRESSLINES, name);

    return result;
};
export const isCityStateCountryValid = async (name: string) => {
    const result = await testRegEX(RegExpressions.CITYCOUNTRYSTATE, name);

    return result;
};

export const generateKey = (): string => {
    let result = '';
    const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_$%#$%^%';
    const charactersLength = characters.length;
    for (let i = 0; i < 8; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
        );
    }
    return result;
};

export const getEmbedMapUrl = (location: MapsCoordinates | string) => {
    const LOCATION =
        typeof location === 'object'
            ? `${location?.latitude},${location?.longitude}`
            : `${location}`;

    return `${GOOGLE_EMBED_MAP_URL}?key=${GOOGLE_MAPS_KEY}&q=${LOCATION}`;
};

export const getLocalDateString = (date: string) => {
    return new Date(date).toLocaleDateString();
};

export const checkIfAuthenticated = () => (getTWAccessToken() ? true : false);

export const DisplayTwoDecimalsDigits = (value: number) => {
    return value?.toFixed(2);
};

export const setHasUserAcceptedAgeOver21 = () => {
    localStorage.setItem(UserSplashInfo?.USER_AGE, UserSplashInfo.YES);
};

export const clearSessionStroage = () => {
    localStorage.clear();
};

export const emptySessionStroage = () => {
    sessionStorage.clear();
};

export const getHasUserAcceptedAgeOver21 = () => {
    return localStorage.getItem(UserSplashInfo?.USER_AGE);
};

export const isUserLogin = async () => {
    if (!(await getTWAccessToken())) return false;

    return true;
};

export const getRandomNumber = (len: number) => {
    return Math.floor(Math.random() * len);
};

export const pushPanelEventsInDataLayer = (
    panelName: string,
    pageType: string,
    userId: string | number = ''
) => {
    window.dataLayer.push({
        event: EventGroupsForGA.PANEL_VISIBLE,
        panel_name: panelName,
        page_type: pageType,
        user_id: userId,
        user_is_logged_in: isUserLogin(),
    });
};

export const pushButtonEventsInDataLayer = (
    buttonText: string,
    buttonHref: string,
    pageType: string,
    userId: string | number = ''
) => {
    window.dataLayer.push({
        event: EventGroupsForGA.KEY_BUTTON_CLICK,
        button_text: buttonText,
        button_href: buttonHref,
        page_type: pageType,
        user_id: userId,
        user_is_logged_in: isUserLogin(),
    });
};

export const waitForGivenMilliSec = async (delay: number): Promise<void> => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve();
        }, delay);
    });
};

export const getCustomBundlesPageIdFromSession = () => {
    const filterTag = sessionStorage.getItem(Tags.CUSTOM_BUNDLES_PAGE)
        ? sessionStorage.getItem(Tags.CUSTOM_BUNDLES_PAGE)
        : '';
    return filterTag;
};

export const setCustomBundlesPageIdFromSession = (bundlesId: string) => {
    sessionStorage.setItem(Tags.CUSTOM_BUNDLES_PAGE, bundlesId);
};

export const getJWTTokenForPurchaseHistoryFromSession = () => {
    const filterTag = sessionStorage.getItem(Tags.PURCHASE_HISTORY_JWT)
        ? JSON?.parse(sessionStorage.getItem(Tags.PURCHASE_HISTORY_JWT)!)
        : '';
    return filterTag;
};

export const setJWTTokenForPurchaseHistoryFromSession = (JWTToken: string) => {
    sessionStorage.setItem(Tags.PURCHASE_HISTORY_JWT, JSON.stringify(JWTToken));
};
