import React, { Fragment, Suspense, useEffect } from 'react';
import { GlobalProvider } from './context/globalContext';
import { UserContextProvider } from './context/userContext';
import { ErrorBoundary } from './components/ErrorBoundary/ErrorBoundary';
import { CartProvider } from './context/cartContext';
import { Router } from './routes/Router';
import { GlobalLoader } from './components/Loaders/GLobalLoader';
import HeaderContainer from './components/header/HeaderContainer';
import { HUBSPOT_SCRIPT_ID } from './utils/constants';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import { BundlesProvider } from './context/bundleContext';

function App() {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = `//js.hs-scripts.com/${HUBSPOT_SCRIPT_ID}.js`;
        script.async = true;
        document.head.appendChild(script);
        return () => {
            document.head.removeChild(script);
        };
    }, []);

    return (
        <GlobalProvider>
            <ErrorBoundary>
                <Suspense fallback={<GlobalLoader />}>
                    {/* <UserContextProvider> */}
                        {/* <CartProvider> */}
                            {/* <BundlesProvider> */}
                                <Fragment>
                                    <HeaderContainer />
                                    <Router />
                                </Fragment>
                            {/* </BundlesProvider> */}
                        {/* </CartProvider> */}
                    {/* </UserContextProvider> */}
                </Suspense>
            </ErrorBoundary>
        </GlobalProvider>
    );
}

export default App;
