import { Paths } from '../routes/routes';
import { wineTypes } from './enums';
export { Paths };

const {
    REACT_APP_LOGIN_API_ENDPOINT,
    REACT_APP_UPDATE_USER_API_ENDPOINT,
    REACT_APP_GOOGLE_CLIENT_ID,
    REACT_APP_GOOGLE_SCOPE,
    REACT_APP_FACEBOOK_CLIENT_ID,
    REACT_APP_FACEBOOK_SCOPE,
    REACT_APP_INSTAGRAM_CLIENT_ID,
    REACT_APP_INSTAGRAM_SCOPE,
    REACT_APP_REPO,
    REACT_APP_STRIPE_PUBLISHING_KEY,
    REACT_APP_HUBSPOT_SCRIPT_ID,
} = process.env;
export const LOGIN_API = REACT_APP_LOGIN_API_ENDPOINT;
export const UPDATE_USER_API = REACT_APP_UPDATE_USER_API_ENDPOINT;
export const API_VERSION = 'api/v1';
export const GOOGLE_CLIENT_ID = REACT_APP_GOOGLE_CLIENT_ID;
export const GOOGLE_SCOPE = REACT_APP_GOOGLE_SCOPE;
export const FACEBOOK_CLIENT_ID = REACT_APP_FACEBOOK_CLIENT_ID;
export const FACEBOOK_SCOPE = REACT_APP_FACEBOOK_SCOPE;
export const INSTAGRAM_CLIENT_ID = REACT_APP_INSTAGRAM_CLIENT_ID;
export const INSTAGRAM_SCOPE = REACT_APP_INSTAGRAM_SCOPE;
export const REPO = REACT_APP_REPO;
export const STRIPE_PUBLISHING_KEY = REACT_APP_STRIPE_PUBLISHING_KEY;
export const HUBSPOT_SCRIPT_ID = REACT_APP_HUBSPOT_SCRIPT_ID;

export const GOOGLE_MAPS_KEY = 'AIzaSyDP2_P_NVrTs-txpT1DaM7WbVWJhnHt-0s';
export const GOOGLE_MAPS_URL = `https://maps.googleapis.com/maps/api/staticmap?center=$(LOCATION)&zoom=9&size=1280x1280&markers=color:black%7C$(MARKERS)&key=${GOOGLE_MAPS_KEY}`;
export const GOOGLE_EMBED_MAP_URL =
    'https://www.google.com/maps/embed/v1/place';

export const API_RESPONSE_CODES = {
    UNAUTHORISED: 'Unauthorised',
    FORBIDDEN: 'Forbidden',
    NOT_FOUND: 'Not Found',
    BAD_REQUEST: 'Bad Request',
    INTERNAL_SERVER_ERROR: 'Internal Server Error',
    PAYLOAD_ERROR: '',
    SUCCESS: '',
};

export const API_REQ_TYPE = {
    GET: 'get',
    POST: 'post',
    PATCH: 'patch',
    PUT: 'put',
    DELETE: 'delete',
};

export const ERRORS = {
    NETWORK_ERROR: 'network error',
};

export const CarouselScreen = {
    WIDTH_0: 0,
    WIDTH_320: 320,
    WIDTH_425: 425,
    WIDTH_575: 575,
    WIDTH_768: 768,
    WIDTH_1024: 1024,
};
export const ScreenSettings = {
    items: 1,
    nav: true,
    loop: false,
    dots: false,
};

export const HomePageAPIs = {
    HOME: 'cms/home',
    SURVEY_EXPERT_PICKS: 'shopify/order/survey/expertpick',
    SURVEY_CAPTURE_SUBMIT: 'customer/survey/form',
};

export const ProductDetailsPageAPIs = {
    PRODUCTDETAILS: 'shopify/product/id',
};

export const CartPageAPIs = {
    Cart: 'shopify/cart/',
};
export const MultipleCartItemAPIs = {
    CartItems: 'shopify/cart/items',
};

export const OrderSummaryPageAPIs = {
    Order: 'shopify/order/summary',
    Order_Gift: 'shopify/giftcard/draft',
};

export const OrderHistoryAPIs = {
    HISTORY_TOKEN: 'shopify/order/product_history',
};

export const DraftOrderAPIs = {
    Draft: 'shopify/order/draft',
};

export const NoHeaderPaths = [
    '/UnsubscribeInstructions',
    '/PrivacyPolicy',
    '/TermsAndConditions',
];

export const BROWSE_PAGE = {
    BROWSE_PAGE_STRUCTURE: 'cms/browse',
    ALL_PRODUCTS: 'shopify/product/',
    EXPERT_PICKS: 'shopify/order/expertpick',
    EXPERT_PICKS_ORDER_SUMMARY_:
        'shopify/order/expertpick/summary?wine_filter_tag=',
    SURVEY_FORM_DATA: 'cms/survey/form',
};

export const BUNDLES = {
    BUNDLES_LANDING_PAGE: 'shopify/bundle',
    BUNDLES: '/bundles',
    BUNDLES_ITEM_BY_ID: 'shopify/bundle',
    BUNDLES_CHECKOUT: 'shopify/bundle/checkout',
    BUNDLES_DRAFT: 'shopify/bundle/draft',
};

export const GIFT_CARD = {
    GIFT_CARD_VARIANTS: 'shopify/giftcard',
    GIFT_CARD_SESSION: 'shopify/giftcard/session',
    GIFT_CARD_CHECKOUT: 'shopify/giftcard/checkout',
};

export const GUEST_USER = {
    GUEST_USER_TOKEN: 'login/guest_token',
};

export const RARE_BOTTLE_ROOM = {
    ALL_PRODUCTS: 'shopify/product/rarebottle',
};

export const DeviceType = {
    DESKTOP: 'desktop',
    TABLET: 'tablet',
    MOBILE: 'mobile',
};

export const FormValidations = {
    emailRegx: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
    passwordRegx: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/,
    nameRegx: /^[a-zA-Z]+[A-Z0-9a-z.]*$/i,
    minAge: 21,
    maxAge: 100,
};
export const VoicePageAPIs = {
    HOME: 'cms/voices',
    VOICE_DETAILS_API: 'shopify/voice/id?id=',
};
export const OrderPageAPIs = {
    HOME: 'shopify/order',
    STATUS_BY_ID: 'shopify/order/status/id?order_id=',
};

export const ProfileDataAPIs = {
    HOME: 'customer/',
    SUB_PLAN: 'customer/about_customer',
};

export const PasswordAPIs = {
    HOME: 'customer/password',
    FORGOT_PASSWORD: 'customer/forgot?email=',
    RESET_PASSWORD: 'customer/reset?token=',
};

export const AccountPageAPIs = {
    ACCOUNT: 'customer/',
};

export const FAQPageAPIs = {
    FAQ: 'cms/faq',
};
export const MediaPageAPIs = {
    MEDIA_PAGE: 'cms/media_page',
};

export const WhoWeArePageAPIs = {
    WhoWeAre: 'cms/who_we_are',
};

export const SubscriptionAPIs = {
    ALL_PLANS: 'customer/subscription/plan',
};
export const SkipAMonthAPIs = {
    SKIP_MONTH: 'customer/subscription/skip_month',
};

export const HowItWorksAPIs = {
    HOW_IT_WORKS: 'cms/how_it_works',
};

export const AddressBookAPIs = {
    ADDRESS: 'customer/address',
    DEFAULT_SHIPPING_ADDRESS: 'customer/address/default',
    BILLING_ADDRESS: 'payment/customer/address',
};

export const PaymentMethodsAPIs = {
    PAYMENT_METHOD: 'payment/method',
    DEFAULT_PAYMENT_METHOD: 'payment/method/default',
    PAYMENT_INTENT: 'payment/intent',
    PAYMENT_PUBLICATION_KEY: 'payment/publishable_key',
    PAYMENT_INTENT_SETUP: 'payment/setup_intent',
    PAYMENT_PUBLICATION_KEY_GUEST: 'payment/guest/publishable_key',
    PAYMENT_INTENT_SETUP_GUEST: 'payment/guest/setup_intent',
    PAYMENT_INTENT_GUEST: 'payment/guest/intent',
};

export const SubscriptionPlans = {
    TWO_BOTTLES: 2,
    SIX_BOTTLES: 6,
    TWELVE_BOTTLES: 12,
};

export const SubscribedStatus = {
    SUBSCRIBED: 'subscribed',
    CANCELLED: 'cancelled',
    PAUSED: 'paused',
};

export const SelectedInfo = [
    'type',
    'bottleSize',
    'vintage',
    'country',
    'abv',
    'region',
];

export const RegExpressions = {
    NAME: /^[a-zA-Z]{1}[a-zA-Z -]{0,29}$/, // only alphabets and space allowed upto 30 characters
    FULLNAME: /^[a-zA-Z]{1}[a-zA-Z -]{0,99}$/, // only alphabets and space allowed upto 100 characters
    //MOBNUMBER: /^[0-9]{10}$/g, // only numeric 10 digit limit
    MOBNUMBER:
        /^[+]?(1\-|1\s|1|\d{3}\-|\d{3}\s|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/, // mobile number
    ZIPCODE: /^\d{5}$/, // only numeric 5 digit limit
    ADDRESSLINES: /^[A-Za-z0-9-_,\/ ()#]+$/, // alphanumeric with space, hyphen, comma, frontslash(/)
    CITYCOUNTRYSTATE: /^[a-zA-Z ]*$/, // only alphabets and space allowed (specially applicable for city, state and country fields)
    ALPHANUMERIC: /^[a-zA-Z0-9]*$/, // only alphabets and numbers allwoed.
    GREETINGMESSAGE: /^[a-zA-Z0-9!."'&$(),\- ’]+$/, // allows alphanumeric, exclamation, dot, comma, quotes, dollar, parenthesis, ’, hyphen and ampersand
};

export const BREAD_CRUMBS_KEY = 'bread_data';

export const ToastType = {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    SUCCESS: 'success',
    LIGHT: 'light',
    DARK: 'dark',
    INFO: 'info',
    WARNING: 'warning',
    DANGER: 'danger',
};

export const aspectRatio: any = {
    DEFAULT: {
        DESKTOP: '1:1',
        MOBILE: '1:1',
    },
    STORIES: {
        DESKTOP: '1:1.4',
        MOBILE: '1:1.4',
    },
    CAROUSEL: {
        DESKTOP: '1:1.35',
        MOBILE: '1:1.35',
    },
    HEROBANNERHORIZONTAL: {
        DESKTOP: '16:9',
        MOBILE: '16:9',
    },
    HEROBANNERVERTICAL: {
        DESKTOP: '1:1',
        MOBILE: '1:1.35',
    },
    SIDELINEBLOCK: {
        DESKTOP: '1:1.2',
        MOBILE: '1:1',
    },
    MAINWRAPPER: {
        DESKTOP: '4:3',
        MOBILE: '1:0.8',
    },
    LIFESTYLECATEGORIES: {
        DESKTOP: '1:1.3',
        MOBILE: '1:1.3',
    },
    HPWHYWEDIFF: {
        DESKTOP: '1:0.5',
        MOBILE: '1:0.5',
    },
};

export const occasionHeaderList = {
    CASUAL_GROUP_GATHERING: 'Casual Group Gathering',
    Formal_GROUP_GATHERING: 'Formal Group Gathering',
    HOST_DINNER_PARTY: 'Host Dinner Party',
    UNPLUG_AND_CHILL: 'Unplug & Chill',
    WEATHER_APPROPRIATE: 'Weather Appropriate',
    OVERALL: 'Overall',
};

export const occasionHeaderArray = [
    occasionHeaderList.CASUAL_GROUP_GATHERING,
    occasionHeaderList.Formal_GROUP_GATHERING,
    occasionHeaderList.HOST_DINNER_PARTY,
    occasionHeaderList.UNPLUG_AND_CHILL,
    occasionHeaderList.WEATHER_APPROPRIATE,
    occasionHeaderList.OVERALL,
];

export const wineTypesArray = [
    wineTypes.RED,
    wineTypes.ORANGE,
    wineTypes.WHITE,
    wineTypes.SPARKLING,
    wineTypes.ROSE,
];

export const regexSymbols =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

export const PageKeys = {
    HOME: 'home',
    BY_SUBSCRIPTION: 'bySubscription',
    BUNDLES: 'bundles',
    BY_BOTTLE: 'byBottle',
    VOICES: 'stories',
    WHO_WE_ARE: 'whoWeAre',
    WHY_WE_ARE_DIFFERENT: 'whyWeAreDifferent',
    PRODUCT_DETAILS: 'productDetails',
    VOICE_DETAILS: 'voiceDetails',
    ACCOUNT: 'account',
    ORDERS: 'orders',
    ADDRESS_BOOK: 'addressBook',
    MY_SUBSCRIPTION: 'mySubscription',
    GIFT_CARD: 'giftCard',
    GIFT_CARD_BILLING_INFO: 'giftCardBillingInfo',
    BUNDLES_BILLING_INFO: 'bundlesBillingInfo',
    YOURWINES: 'yourwines',
};

export const videoAspectRatio = {
    MOBILE: '9x16',
    DESKTOP: '16x9',
};

export const accountMenuItems = [
    {
        menuLabel: 'Account',
        menuHref: Paths.ACCOUNT,
    },
    // {
    //     menuLabel: "Wines You've Liked",
    //     menuHref: Paths.WINES_YOU_HAVE_LIKED,
    // },
    {
        menuLabel: 'Order Details',
        menuHref: Paths.ORDERS,
    },
    {
        menuLabel: 'Your Addresses',
        menuHref: Paths.ADDRESS_BOOK,
    },
    {
        menuLabel: 'Subscription',
        menuHref: Paths.MY_SUBSCRIPTION,
    },
    // {
    //     menuLabel: 'Social Profile',
    //     menuHref: '#',
    // },
];

export const BundleSessionKeys = {
    BUNDLES_CART: 'Bundles_Cart',
};

export const mockCTA = {
    subscribedUserMessage: `<p><u><strong>CLICK HERE</strong></u><strong> + CHOOSE YOUR WINES.</strong></p>`,
    unsubscribedUserMessage: `<p><strong>USE CODE </strong><u><strong>WAVES20</strong></u><strong> FOR $20 + 20% OFF.</strong></p>`,
};

export const skipMonthMessage = {
    skipMonthSucessMessage: `Your request has been successfully submitted. Your next shipment will be in `,
    skipMonthPopupMessage: `Thank you for letting us know. We will skip your shipment next month.<br/>
        In case you change your mind, you can always come back and buy anytime.`,
};

export const bundlesHeader = {
    disclaimer: `*Note: Due to the limited nature of these small-production wines, your shipment may not contain the exact wines pictured here.`,
    description: `Behold your bundle! We support small farmers who
    make limited quantities. Our experts have selected
    the best bottles currently in stock.`,
};

export const pauseSubscriptionMessage = {
    pauseSubscriptionSucessMessage: `YOU'VE SUCCESSFULLY PAUSED YOUR SUBSCRIPTION.`,
    pauseSubscriptionPopupMessage: `We will pause your subscription. You will not receive any monthly shipments, but can continue to purchase wines whenever you'd like. To resume your subscription, simply return to your account page and choose the "resume subscription" option.`,
};

export const resumeSubscriptionMessage = {
    resumeSubscriptionSucessMessage: `YOU'VE RESUMED YOUR MONTHLY SUBSCRIPTION.`,
    resumeSubscriptionPopupMessage: `WELCOME BACK <br> Your monthly subscription will resume with our upcoming subscription cycle. We're thrilled to have you back!`,
};
