import React, { FC, lazy, useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Paths } from './routes';
import { ProtectedRoute } from './ProtectedRoute';
import {
    getHasUserAcceptedAgeOver21,
    getTWAccessToken,
} from '../components/common/utils';
import { PaymentRoute } from './PaymentRoute';
import { UserSplashInfo } from '../components/common/constants';
import { GiftCardRoute } from './GiftCardRoute';
import MediaPageContainer from '../pages/MediaPage/MediaPageContainer';
import BundleRoute from './BundleRoute';
import PurchaseHistoryRoute from './PurchaseHistoryRoute';

// Lazy imports
//-------------------------------------------------------------------
const BelowAge = lazy(() => import('../pages/belowAge/belowAge'));
const LandingPage = lazy(() => import('../pages/newLandingPage/LandingPage'));
const HomePageContainer = lazy(() => import('../pages/home/homePageContainer'));
const PrivacyPolicyPage = lazy(
    () => import('../pages/PrivacyPolicy/PrivacyPolicyPage')
);
const ProductDetailsContainer = lazy(
    () => import('../pages/ProductDetails/productDetailsContainer')
);
const RareBottleRoomContainer = lazy(
    () => import('../pages/RareBottleRoom/RareBottleRoomContainer')
);
const SignInPageContainer = lazy(
    () => import('../pages/signin/signinPageContainer')
);
const SignupPageContainer = lazy(
    () => import('../pages/signup/signupPageContainer')
);
const TermsAndConditionsPage = lazy(
    () => import('../pages/TermsAndConditions/TermsAndConditionsPage')
);
const UnsubscribeInstructionsPage = lazy(
    () => import('../pages/UnsubscribeInstructions/UnsubscribeInstructions')
);
const VoiceContainer = lazy(() => import('../pages/voice/voiceContainer'));
const VoiceDetailsContainer = lazy(
    () => import('../pages/VoiceDetails/VoiceDetailsContainer')
);
const WhoWeAre = lazy(() => import('../pages/WhoWeAre/WhoWeAre'));
const HowItWorksContainer = lazy(
    () => import('../pages/HowItWorks/HowItWorksContainer')
);
const FAQContainer = lazy(() => import('../pages/FAQ/FAQContainer'));
const ForgotPasswordContainer = lazy(
    () => import('../pages/ForgotPassword/ForgotPasswordContainer')
);
const ResetPasswordContainer = lazy(
    () => import('../pages/ResetPassword/ResetPasswordContainer')
);
const ChangePassword = lazy(
    () => import('../components/account/ChangePassword')
);
const ProfileEdit = lazy(() => import('../components/account/ProfileEdit'));

const BrowseContainer = lazy(() => import('../pages/Browse/BrowseContainer'));

const CartContainer = lazy(() => import('../pages/Cart/CartContainer'));

const OrderSummaryContainer = lazy(
    () => import('../pages/OrderSummary/OrderSummaryContainer')
);
const PaymentContainer = lazy(
    () => import('../pages/Payment/PaymentContainer')
);
const ErrorPage = lazy(() => import('../pages/error/ErrorPage'));

const PaymentResponsePage = lazy(
    () => import('../pages/Payment/PaymentResponsePage')
);
const SideMenuContainer = lazy(
    () => import('../components/SideMenu/sideMenuContainer')
);

const SplashPage = lazy(() => import('../pages/splash/SplashPage'));

export const Router: FC = () => {
    const {
        // Public
        DEFAULT,
        HOME,
        SIGN_UP,
        SIGN_IN,
        BELOWAGE,
        RARE_BOTTLE_ROOM,
        BUNDLES,
        PRODUCT_DETAILS,
        VOICE,
        VOICE_DETAILS,
        WHO_WE_ARE,
        // WHY_WE_ARE_DIFFERENT,
        HOW_IT_WORKS,
        PRIVACY_POLICY,
        TERMS_AND_CONDITIONS,
        UNSUBSCRIBEINSTRUCTIONS,
        FAQS,
        PRESS_PAGE,
        FORGOT_PASSWORD,
        RESET_PASSWORD,
        REDIRECT,
        GIFT_CARD,
        BROWSE,
        PURCHASE_HISTORY,

        //Private
        CART,
        PAYMENT_CHECKOUT,
        MY_SUBSCRIPTION,
        ACCOUNT,
        ORDERS,
        ORDER_SUMMARY,
        ADDRESS_BOOK,
        PERSONAL_INFORMATION,
        CHANGE_PASSWORD,
        PAYMENT_RESPONSE,
    } = Paths;

    const isAuthenticated = getTWAccessToken() ? true : false;
    const [isUserOver21, setisUserOver21] = useState<boolean>(false);

    const { pathname } = useLocation();

    useEffect(() => {
        const isOver21 = getHasUserAcceptedAgeOver21();
        isOver21 === UserSplashInfo?.YES
            ? setisUserOver21(true)
            : setisUserOver21(false);
    }, [pathname]);

    return (
        <Routes>
            {isUserOver21 ? (
                <>
                    <Route path={'/*'} element={<LandingPage />} />
                    {/* <Route path={HOME} element={<HomePageContainer />} /> */}
                    {/* <Route path={SIGN_UP} element={<SignupPageContainer />} />
                    <Route path={SIGN_IN} element={<SignInPageContainer />} />
                    <Route
                        path={PRODUCT_DETAILS}
                        element={<ProductDetailsContainer />}
                    />
                    <Route
                        path={RARE_BOTTLE_ROOM}
                        element={<RareBottleRoomContainer />}
                    />
                    <Route path={`${BUNDLES}/*`} element={<BundleRoute />} />
                    <Route path={VOICE} element={<VoiceContainer />} />
                    <Route
                        path={VOICE_DETAILS}
                        element={<VoiceDetailsContainer />}
                    />
                    <Route path={WHO_WE_ARE} element={<WhoWeAre />} />
                    <Route
                        path={HOW_IT_WORKS}
                        element={<HowItWorksContainer />}
                    />
                    <Route path={FAQS} element={<FAQContainer />} />
                    <Route path={PRESS_PAGE} element={<MediaPageContainer />} />
                    <Route
                        path={FORGOT_PASSWORD}
                        element={<ForgotPasswordContainer />}
                    />
                    <Route
                        path={RESET_PASSWORD}
                        element={<ResetPasswordContainer />}
                    />
                    <Route path={BELOWAGE} element={<BelowAge />} />
                    <Route
                        path={PRIVACY_POLICY}
                        element={<PrivacyPolicyPage />}
                    />
                    <Route
                        path={TERMS_AND_CONDITIONS}
                        element={<TermsAndConditionsPage />}
                    />
                    <Route
                        path={UNSUBSCRIBEINSTRUCTIONS}
                        element={<UnsubscribeInstructionsPage />}
                    />
                    <Route
                        path={`${GIFT_CARD}/*`}
                        element={<GiftCardRoute />}
                    />
                    <Route
                        path={`${PURCHASE_HISTORY}/*`}
                        element={<PurchaseHistoryRoute />}
                    />
                    <Route path={BROWSE} element={<BrowseContainer />} />
                    <Route
                        path={CART}
                        element={
                            <ProtectedRoute
                                Component={<CartContainer />}
                                isAuthenticated={isAuthenticated}
                            />
                        }
                    />
                    <Route
                        path={ORDER_SUMMARY}
                        element={
                            <ProtectedRoute
                                Component={<OrderSummaryContainer />}
                                isAuthenticated={isAuthenticated}
                            />
                        }
                    />
                    <Route
                        path={CHANGE_PASSWORD}
                        element={
                            <ProtectedRoute
                                Component={<ChangePassword />}
                                isAuthenticated={isAuthenticated}
                            />
                        }
                    />
                    <Route
                        path={PERSONAL_INFORMATION}
                        element={
                            <ProtectedRoute
                                Component={<ProfileEdit />}
                                isAuthenticated={isAuthenticated}
                            />
                        }
                    />
                    <Route
                        path={ACCOUNT}
                        element={
                            <ProtectedRoute
                                Component={<SideMenuContainer path={ACCOUNT} />}
                                isAuthenticated={isAuthenticated}
                            />
                        }
                    />
                    <Route
                        path={ORDERS}
                        element={
                            <ProtectedRoute
                                Component={<SideMenuContainer path={ORDERS} />}
                                isAuthenticated={isAuthenticated}
                            />
                        }
                    />
                    <Route
                        path={ADDRESS_BOOK}
                        element={
                            <ProtectedRoute
                                Component={
                                    <SideMenuContainer path={ADDRESS_BOOK} />
                                }
                                isAuthenticated={isAuthenticated}
                            />
                        }
                    />
                    <Route
                        path={MY_SUBSCRIPTION}
                        element={
                            <PaymentRoute
                                Component={
                                    <SideMenuContainer path={MY_SUBSCRIPTION} />
                                }
                                isAuthenticated={isAuthenticated}
                            />
                        }
                    />
                    <Route
                        path={PAYMENT_CHECKOUT}
                        element={
                            <PaymentRoute
                                Component={<PaymentContainer />}
                                isAuthenticated={isAuthenticated}
                            />
                        }
                    />
                    <Route
                        path={PAYMENT_RESPONSE}
                        element={
                            <PaymentRoute
                                Component={<PaymentResponsePage />}
                                isAuthenticated={isAuthenticated}
                            />
                        }
                    />
                    <Route path={REDIRECT} element={<ErrorPage />} /> */}
                </>
            ) : (
                <Route path={REDIRECT} element={<SplashPage />} />
            )}
        </Routes>
    );
};
