export { ReactComponent as DesktopLogo } from './images/logos/desktop_the_waves_logo.svg';
export { ReactComponent as MobileLogo } from './images/logos/mobile_the_waves_logo.svg';
export { ReactComponent as Plus } from './icons/plus.svg';
export { ReactComponent as SearchIcon } from './icons/search_icon.svg';
export { ReactComponent as ProfileIcon } from './icons/profile_icon.svg';
export { ReactComponent as ProfileLogoutIcon } from './icons/profile_logout_icon.svg';
export { default as HomeScreenImg } from './images/home_screen_img.png';
export { default as HomeScreenMobileImg } from './images/home_screen_mobile_img.png';
export { default as Bottle1 } from './images/bottle/b1.png';
export { default as Bottle2 } from './images/bottle/b2.png';
export { default as Bottle3 } from './images/bottle/b3.png';
export { default as Bottle4 } from './images/bottle/b4.png';
export { default as Bottle5 } from './images/bottle/b5.png';
export { default as Bottle6 } from './images/bottle/b6.png';
export { default as Bottle8 } from './images/bottle/b8.png';
export { default as LeftArrow } from './icons/left_arrow.svg';
export { default as RightArrow } from './icons/right_arrow.svg';
export { default as BottleBackground } from './images/bottle/bottleBackground.png';
export { default as Evian } from './images/bottle/evian_image.png';
export { default as Convert } from './images/bottle/convert.png';
export { default as WomenWineMaker } from './images/bottle/women_wine_makers.png';
export { ReactComponent as HomeCurveText } from './images/home_curve_text.svg';
export { ReactComponent as SemiCircles } from './images/product_page_icons/vibes-semicircles.svg';
export { ReactComponent as PlusSign } from './images/product_page_icons/plus-sign.svg';
export { ReactComponent as MinusSign } from './images/product_page_icons/minus-sign.svg';
export { default as MobileHeroBanner } from './images/hero_banners/Mobile_Hero_Banner.png';
export { ReactComponent as MobileWaws } from './images/hero_banners/Mobile_Waves.svg';
export { default as Slider1 } from './images/hero_banners/Slider_1.png';
export { default as S1 } from './images/Story/S1.png';
export { default as S2 } from './images/Story/S2.png';
export { default as S3 } from './images/Story/S3.png';
export { default as VoiceBanner } from './images/voices/voiceBanner.png';
export { default as close } from './icons/signup_close.svg';
export { ReactComponent as LogoShort } from './images/logoShort.svg';
export { default as WineComeSoon } from './images/wine_come_soon.jpg';
export { default as DesktopWaves } from './images/desktop-wave-logo.png';
export { ReactComponent as TheWavesLongLogo } from './images/THE_WAVES.svg';
export { ReactComponent as Cart } from './icons/Cart.svg';
export { ReactComponent as ShoppingCart } from './icons/shopping_cart.svg';
export { ReactComponent as AddCart } from './icons/addCartButton.svg';
export { ReactComponent as CartPlus } from './icons/plus.svg';
export { ReactComponent as EmptyBottle } from './icons/EmptyBottle.svg';
export { ReactComponent as CountRing } from './icons/countRing.svg';
export { ReactComponent as CartRemove } from './icons/cartRemove.svg';
export { ReactComponent as CloseIcon } from './icons/signup_close.svg';
export { ReactComponent as OutOfStock } from './icons/outOfStock.svg';
export { ReactComponent as PoweredByStripe } from './icons/powered_by_stripe.svg';
export { ReactComponent as HowItsWorksDesktopImage } from './how_its_works/Full Pic - Beige - Wine Bottle Icon.svg';
export { ReactComponent as HowItsWorksMobileImage } from './how_its_works/3.svg';
export const DefaultProductImage =
    'https://images.contentstack.io/v3/assets/blt02919b4ce2179a4d/blt198e3bb96a754cee/642fbff7884f2510c38941d1/Default_Product_Image.jpg';
    export { default as CofoundersWaves } from './images/landing_page/co-founders_Waves.jpg';
    export { default as WineCellar } from './images/landing_page/wine_cellar.jpg';
