import jwtDecode from 'jwt-decode';
import { customerAPI, loginAPI } from '../components/common/api.endpoints';
import { TW_TOKEN } from '../components/common/constants';
import { ProfileDataAPIs } from '../utils/constants';
import { postData } from './service';

interface AccessToken {
    access_token: string;
    token_type: string;
}

export interface SignUpFormValues {
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    email: string;
    password: string;
    confirmPassword: string;
}

interface UserInfo {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    contact_no: string;
    date_of_birth: string;
    oauth_user_id: string;
    exp: number;
}

export interface QuestionPostData {
    // customer_id: number;
    // kind_of_drinker: string;
    // occasions: string[];
    preference: string;
    is_subscribed: boolean;
}

export interface ErrorData {
    code: number;
    message: string;
}

export const getUserInfoFromResponse = (response: any): UserInfo => {
    const token: AccessToken = response.data;
    localStorage.setItem(TW_TOKEN, token.access_token);
    const userinfo: UserInfo = jwtDecode(token.access_token);
    return userinfo;
};

export const handleSignUpByAccount = async (
    code: string,
    authProviders: string
): Promise<any | undefined> => {
    const payload = {
        access_code: code,
        auth_provider: authProviders,
    };
    try {
        return await postData(loginAPI.signUpURL, payload);
    } catch (e) {
        console.log('Login Error');
    }
};

export const handleSignUpByEmail = async (
    signUpFormValues: SignUpFormValues
): Promise<any | undefined> => {
    let payload = {
        first_name: signUpFormValues.firstName,
        last_name: signUpFormValues.lastName,
        email: signUpFormValues.email,
        date_of_birth: signUpFormValues.dateOfBirth,
        password: signUpFormValues.password,
        confirm_password: signUpFormValues.confirmPassword,
    };
    try {
        return await postData(loginAPI.signUpURL, payload);
    } catch (e) {
        console.log('Login Error');
    }
};

export const getUserDataFromLocalToken = () => {
    try {
        const access_token = localStorage.getItem(TW_TOKEN);
        if (access_token) {
            const userinfo: UserInfo = jwtDecode(access_token);
            return userinfo;
        }
        return null;
    } catch (e) {
        console.log('Token Error');
    }
};

export const handleSignInByAccount = async (
    code: string,
    authProviders: string
): Promise<UserInfo | undefined> => {
    const payload = {
        access_code: code,
        auth_provider: authProviders,
    };
    try {
        return await postData(loginAPI.signInURL, payload);
    } catch (e) {
        console.log('Login Error');
    }
};

export const handleSignInByEmail = async (
    email: string,
    password: string
): Promise<UserInfo | undefined> => {
    let payload = {
        email: email,
        password: password,
    };
    try {
        return await postData(loginAPI.signInURL, payload);
    } catch (e) {
        console.log('Login Error');
    }
};

export const sendSubPlanChoice = async (body: any) => {
    const { data, error } = await postData(ProfileDataAPIs.SUB_PLAN, body);
    if (data) {
        return data;
    } else throw error;
};
