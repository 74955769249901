export const Paths = {
    // Public Routes ----------------------------------------------

    REDIRECT: '/*',
    DEFAULT: '/',
    BELOWAGE: '/below_age', //belowAge
    PRIVACY_POLICY: '/privacy_policy', //PrivacyPolicy
    TERMS_AND_CONDITIONS: '/terms_and_conditions', //TermsAndConditions
    UNSUBSCRIBEINSTRUCTIONS: '/unsubscribe_instructions', //UnsubscribeInstructions
    SIGN_UP: '/signup',
    HOME: '/home',
    SIGN_IN: '/signin',
    PRODUCT_DETAILS: '/product_details',
    VOICE: '/stories',
    VOICE_DETAILS: '/voice-details',
    RARE_BOTTLE_ROOM: '/rare_bottle_room',
    WHO_WE_ARE: '/who_we_are',
    // WHY_WE_ARE_DIFFERENT: '/why_we_are_different',
    HOW_IT_WORKS: '/how_it_works',
    FAQS: '/faqs',
    PRESS_PAGE: '/press_page', // previously media_page
    FORGOT_PASSWORD: '/forgot_password',
    RESET_PASSWORD: '/reset-password',

    BUNDLES: '/natural-wine-bundles',
    BUNDLES_PAGE: '/',
    // bundles sub routes
    BUNDLES_BILLING_INFO: '/billing_info',
    BUNDLES_PAYMENT: '/payment',
    BUNDLES_PAYMENT_RESPONSE: '/payment_response',

    GIFT_CARD: '/gift_card',
    // giftcard sub routes
    GIFT_CARD_VARIENTS: '/',
    GIFT_CARD_BILLING_INFO: '/billing_info',
    GIFT_CARD_PAYMENT: '/payment',
    GIFT_CARD_PAYMENT_RESPONSE: '/payment_response',

    //Wine history
    PURCHASE_HISTORY: '/purchase-history',
    PURCHASE_HISTORY_PAGE: '/',
    PURCHASE_HISTORY_PRODUCTS: '/products',

    // Protected Routes ----------------------------------------------

    BROWSE: '/browse',
    CART: '/cart',
    ACCOUNT: '/account',
    ORDERS: '/orders',
    ORDER_SUMMARY: '/order-summary',
    ADDRESS_BOOK: '/address-book',
    PERSONAL_INFORMATION: '/personal-information',
    CHANGE_PASSWORD: '/change-password',
    WINES_YOU_HAVE_LIKED: '/wines-you-have-liked',
    PAYMENT_RESPONSE: '/payment_response',

    // Payment Routes -------------------------------------------------
    PAYMENT_CHECKOUT: '/payment-checkout',
    MY_SUBSCRIPTION: '/my-subscription',
};
